import React from "react";
import unionmon from "../../images/Unionmon2.png";
import unionget from "../../images/Unionget2.png";
import unionaudio from "../../images/Unionaudio2.png";
import unionlive from "../../images/Unionlive2.png";
import {
  Para,
  ParaFirst,
  ParaDiv,
  ParaDivFirst,
  ParaContainer,
  ParaContainerFirst,
  HeadingDivFirst,
  HeadingDiv,
  Heading,
  HeadingFirst,
  InnerContainer,
} from "../../styles/ParaHeading/ParaHeading.styled";

const data = [
  {
    heading: "monetization platform",
    para: "We believe every talent must be not only expressed but valued as well. Participate in open mics, do live events, build your own podcast channel and start monetizing your talent. While you focus on growing your talent, we help you connect with the audience and streamline backend work. Whatever your region or language, with Quhu, you can convert your passion into a full-time career.",
    background: unionmon,
    align: "right",
  },
  {
    heading: "live sessions",
    para: "Start your own live shows and engage with your audience in real-time using our Go-Live feature. Keep your live shows public or restricted to a few people - Quhu enables you to engage with your audience just the way you want. Whether you’re using the live feature to connect with your audience in real-time or collaborate with other creators, never let your creation go to waste. Once the live event is over, store the bite in your storage folder or upload it for the world to hear.",
    background: unionlive,
    align: "left",
  },
  {
    heading: "audio emojis",
    para: "We believe every talent must be not only expressed but valued as well. Participate in open mics, do live events, build your own podcast channel and start monetizing your talent. While you focus on growing your talent, we help you connect with the audience and streamline backend work. Whatever your region or language, with Quhu, you can convert your passion into a full-time career.",
    background: unionaudio,
    align: "right",
  },
  {
    heading: "get insights",
    para: "Learn how your audience engages with your audio bite using our Insights feature. The tool helps you gain a better understanding of their likes and dislikes so that you can create better content, grow your talent, and most importantly, transform your audience into raving fans.",
    background: unionget,
    align: "left",
  },
];

const getContent = (el) => {
  if (el.align === "right") {
    return (
      <ParaContainer background={el.background}>
        <InnerContainer
          flexDirection={el.align === "right" ? "column" : "column-reverse"}
        >
          <HeadingDiv align={el.align}>
            <Heading maxwidth="18.875rem" fontSize="3.8rem" align="flex-end">
              {el.heading}
            </Heading>
          </HeadingDiv>
          <ParaDiv align={el.align === "right" ? "flex-start" : "flex-end"}>
            <Para align={el.align === "right" ? "flex-start" : "flex-end"}>
              {el.para}
            </Para>
          </ParaDiv>
        </InnerContainer>
      </ParaContainer>
    );
  } else {
    return (
      <ParaContainer background={el.background}>
        <InnerContainer
          flexDirection={el.align === "right" ? "column" : "column-reverse"}
        >
          <ParaDiv align={el.align === "right" ? "flex-start" : "flex-end"}>
            <Para align={el.align === "right" ? "flex-start" : "flex-end"}>
              {el.para}
            </Para>
          </ParaDiv>
          <HeadingDiv align={el.align}>
            <Heading maxwidth="18.875rem" fontSize="3.8rem" align="flex-start">
              {el.heading}
            </Heading>
          </HeadingDiv>
        </InnerContainer>
      </ParaContainer>
    );
  }
};

const ParaHeading = () => {
  return (
    <>
      <ParaContainerFirst
        style={{
          backgroundImage: `url(${unionlive})`,
          backgroundSize: "contain",
          backgroundRepeat: "no-repeat",
          backgroundPosition: "center",
          width: "100%",
        }}
      >
        <InnerContainer flexDirection="column-reverse">
          <ParaDivFirst align="flex-end">
            <ParaFirst align="flex-start" lineHeight="2.625rem">
              With our creator tool, make your audio bite world-ready
              effortlessly. Tap on + sign and start recording or upload one of
              your finished audio files. Once done, add background music, use a
              voice filter from our extensive library, trim your audio, reduce
              background noise- all in just a few tap.Preview and store it in
              the unlimited storage folder or share it for the world to hear.
            </ParaFirst>
          </ParaDivFirst>
          <HeadingDivFirst align="center">
            <HeadingFirst
              maxwidth="18.875rem"
              fontSize="4.2rem"
              align="flex-end"
            >
              create with creator tools
            </HeadingFirst>
          </HeadingDivFirst>
        </InnerContainer>
      </ParaContainerFirst>

      {data.map((el) => {
        return getContent(el);
      })}
    </>
  );
};

export default ParaHeading;
