module.exports = {
  colors: {
    primary: "#FF7070",
    primaryDark: " #ff6666",
    peach: "#F98C8C",
    white: "#FFFFFF",
    purple: "#7D59E5",
    accents: "#3A2C62",
    darkPurple: "#120B26",
    gray: "#CCCCCC",
    lightGray: "#EEEEEE",
  },
  gradients: {
    quhulogo:
      "linear-gradient(106.5deg, #7D59E5 -4.85%, #805AE2 8.97%, #895BDA 18.85%, #995ECC 27.73%, #B062B7 35.63%, #CD679D 43.53%, #F06E7E 51.43%, #FD7072 53.41%, #FF7070 93.89%)",
    playstoreLogo: "linear-gradient(127.04deg, #EEC3C3 12.32%, #7D59E4 90.84%)",
    downloadPlaceholder:
      "linear-gradient(275.22deg, #1E1144 2.19%, #4C3886 92.49%)",
  },
  dimensions: {
    mobile: "425px",
  },
};
