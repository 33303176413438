import styled from "styled-components";
import theme from "../../styles/theme";

export const Heading = styled.p`
  // max-width:${({ maxwidth }) => (maxwidth ? maxwidth : "auto")};
  color: black;
  font-size: ${({ fontSize }) => (fontSize ? fontSize : "1em")};
  font-family: "DM Sans";
  font-weight: 800;
  text-align: ${({ align }) => (align === "flex-end" ? "right" : "left")};
  color: white;
  flex: 0 0 43%;
  @media (max-width: ${theme.dimensions.mobile}) {
    text-align: ${({ align }) => (align === "flex-end" ? "left" : "right")};
    font-size: 2.125rem;
    line-height: 2.815rem;
  }
`;

export const HeadingFirst = styled.p`
  // max-width:${({ maxwidth }) => (maxwidth ? maxwidth : "auto")};
  color: black;
  font-size: ${({ fontSize }) => (fontSize ? fontSize : "1em")};
  font-family: "DM Sans";
  font-weight: 800;
  text-align: ${({ align }) => (align === "flex-end" ? "right" : "left")};
  color: white;
  flex: 0 0 43%;
  @media (max-width: ${theme.dimensions.mobile}) {
    flex: 0 0 100%;
    font-size: 3.125rem;
    line-height: 3.375rem;
  }
`;

export const ParaDiv = styled.div`
  flex: 0 0 45%;
  display: flex;
  justify-content: ${({ align }) => (align ? align : "center")};
  align-items: center;
`;

export const ParaDivFirst = styled.div`
  flex: 0 0 55%;
  display: flex;
  justify-content: ${({ align }) => (align ? align : "center")};
  align-items: center;
`;

export const ParaContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 3em auto;
  justify-content: space-between;
  width: 100%;
  align-items: center;
  background-image: url(${({ background }) => background});
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  background-position: center center;
  width: 100%;
`;

export const ParaContainerFirst = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 3em auto;
  justify-content: space-between;
  align-items: center;
  background-image: url(${({ background }) => background});
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  width: 100%;
`;

export const Para = styled.p`
  font-weight: 400;
  font-family: "DM Sans";
  text-align: ${({ align }) => (align ? align : "center")};
  font-size: 1.1em;
  color: rgba(145, 137, 170, 1);
  line-height: ${({ lineHeight }) => (lineHeight ? lineHeight : "1.875rem")};
  align-items: center;
  @media (max-width: ${theme.dimensions.mobile}) {
    text-align: ${({ align }) => (align === "flex-start" ? "left" : "right")};
    font-size: 1rem;
    line-height: 1.625rem;
  }
`;

export const ParaFirst = styled.p`
  font-weight: 400;
  font-family: "DM Sans";
  text-align: ${({ align }) => (align ? align : "center")};
  font-size: 1.25rem;
  color: rgba(145, 137, 170, 1);
  line-height: 2.325rem;
  // max-width: 80%;
  @media (max-width: ${theme.dimensions.mobile}) {
    text-align: ${({ align }) => (align === "flex-start" ? "right" : "left")};
    font-size: 1rem;
    line-height: 1.75rem;
  }
`;

export const HeadingDiv = styled.div`
  display: flex;
  justify-content: ${({ align }) => (align ? align : "center")};
  flex: 0 0 45%;
  @media (max-width: ${theme.dimensions.mobile}) {
    justify-content: ${({ align }) =>
      align === "right" ? "flex-start" : "flex-end"};
  }
`;

export const HeadingDivFirst = styled.div`
  display: flex;
  justify-content: flex-end;
  flex: 0 0 35%;
`;

export const InnerContainer = styled.div`
  display: flex;
  width: 92%;
  max-width: 1240px;
  margin: auto;
  justify-content: space-between;
  @media (max-width: ${theme.dimensions.mobile}) {
    flex-direction: ${({ flexDirection }) => flexDirection};
    width: 87%;
  }
`;
