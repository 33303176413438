import styled from "styled-components";
import theme from "./theme";

export const FooterWrapper = styled.footer`
  height: 240px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${({ theme }) => theme.colors.darkPurple};
`;

export const FooterContent = styled.div`
  max-width: 1240px;
  width: 92%;
  margin: 0 auto;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: space-around;
  a:hover {
    color: ${theme.colors.primary};
  }
  a > svg {
    fill: white;
  }
  a > svg:hover {
    path {
      fill: ${theme.colors.primary};
    }
  }
`;

export const Button = styled.button`
  background-color: transparent;
  color: white;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 10px 25px;
  width: 145px;
  height: 44px;
  border: 1px solid white;
  box-sizing: border-box;
  border-radius: 22px;
  cursor: pointer;
`;
