import React from "react";
import {
  Circle,
  CircleContent,
  CircleContentBold,
  CircleContainer,
  CircleSectionImage,
  CircleWrapper,
} from "../../styles/Circles/CircleBlobs.styled";
import ManMusicNoLine from "../../images/ManMusicNoLine.png";
const CircleBlobs = () => {
  return (
    <CircleWrapper>
      <CircleSectionImage alt="Man-listening-to-music" src={ManMusicNoLine} />
      <CircleContainer>
        <Circle height={16.1875} id="listen">
          <CircleContent>
            Listen To <CircleContentBold>Audio Bites</CircleContentBold>
          </CircleContent>
        </Circle>

        <Circle height={12.75} id="create">
          <CircleContent>Create interactive audios </CircleContent>
        </Circle>

        <Circle height={17.75} id="edit">
          <CircleContent>
            Edit audios with editing studio to produce better sounding bites
          </CircleContent>
        </Circle>

        <Circle height={21.5} id="comment">
          <CircleContent>
            Comment on Audio Bites using{" "}
            <CircleContentBold>Voicemojis</CircleContentBold>
          </CircleContent>
        </Circle>

        <Circle height={19.5} id="go">
          <CircleContent>Go Live to connect with community </CircleContent>
        </Circle>
      </CircleContainer>
    </CircleWrapper>
  );
};

export default CircleBlobs;
