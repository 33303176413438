import React from "react";
import {
  ContainerWhat,
  Items,
  Row,
  HeadingWhat,
} from "../../styles/WhatsYour/WhatsYour.styled";

const data = [
  ["news", "shayaris", "motivational", "jokes", "coaching"],
  ["astrology", "health", "bhakti", "storytelling"],
  ["standup gigs", "horoscope", "poems", "audio vlogs", "entertainment"],
];

const WhatsYour = () => {
  return (
    <>
      <HeadingWhat fontSize="3.8rem" align="center">
        what's your quhu?
      </HeadingWhat>
      <ContainerWhat>
        <Row just="space-between">
          {data[0].map((el) => (
            <Items random={Math.floor(Math.random() * 5)}>{el}</Items>
          ))}
        </Row>
        <Row just="space-evenly">
          {data[1].map((el) => (
            <Items random={Math.floor(Math.random() * 5)}>{el}</Items>
          ))}
        </Row>
        <Row just="space-between" nomargin>
          {data[2].map((el) => (
            <Items random={Math.floor(Math.random() * 5)}>{el}</Items>
          ))}
        </Row>
      </ContainerWhat>
    </>
  );
};

export default WhatsYour;
