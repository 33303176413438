import styled from "styled-components";
import theme from "../../styles/theme";

export const HeroWrapper = styled.div`
  display: flex;
  flex-direction: row;
  height: 90vh;
  flex-wrap: wrap;
  align-items: flex-start;
  justify-content: center;
  position: relative;
  overflow: hidden;
  margin-top: 70px;
  visibility: hidden;
`;

export const HeroHeading = styled.p`
  font-size: 5.375rem;
  font-family: "DM Sans";
  max-width: ${({ maxwidth }) => (maxwidth ? maxwidth : "100%")};
  z-index: 2;
  position: relative;
  @media (max-width: ${theme.dimensions.mobile}) {
    font-size: 3.125rem;
    max-width: ${({ maxwidth }) => (maxwidth === "250px" ? "220px" : "275px")};
    margin-top: 0;
    margin-bottom: 1rem;
  }
`;

export const CirclesBg = styled.img`
  position: absolute;
  z-index: -1;
  top: 134px;
  left: auto;
`;

export const SubPara = styled.p`
  font-size: 1.125rem;
  line-height: 2.175rem;
  text-align: center;
  max-width: 800px;
  @media (max-width: ${theme.dimensions.mobile}) {
    order: 4;
    font-size: 1rem;
    line-height: 1.75rem;
  }
`;

export const PurpleGradient = styled.div`
  position: absolute;
  width: 923px;
  height: 470px;
  right: 0;
  width: 60%;
  top: 71px;
  margin-left: 1rem;
  background: rgba(125, 89, 229, 0.8);
  filter: blur(130px);
`;

export const HeadingWrapperRight = styled.div`
  align-self: center;
  margin-left: 1rem;
  flex: 1;
  display: flex;
  @media (max-width: ${theme.dimensions.mobile}) {
    // max-width: 85%;
    flex-basis: 100%;
    margin: 0;
    order: 2;
    justify-content: flex-end;
  }
`;

export const RedGradient = styled.div`
  position: absolute;
  width: 1175px;
  height: 470px;
  left: 0;
  width: 60%;
  top: 71px;
  background: rgba(249, 140, 140, 0.89);
  filter: blur(130px);
`;

export const InnerWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  max-width: 1240px;
  width: 92%;
  justify-content: center;
  padding-top: 4rem;
`;

export const HeadingWrapperLeft = styled.div`
  margin-right: 1rem;
  flex: 1;
  display: flex;
  justify-content: flex-end;
  @media (max-width: ${theme.dimensions.mobile}) {
    flex-basis: 100%;
    max-width: 85%;
    margin: 0;
    order: 1;
    justify-content: flex-start;
  }
`;

export const MockupAndButton = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 1rem;
  margin-right: 1rem;
  align-items: center;
  @media (max-width: ${theme.dimensions.mobile}) {
    order: 3;
  }
`;

export const HeroMockup = styled.img`
  width: 246px;
  z-index: 2;
`;

export const LoaderImage = styled.div`
  z-index: -2;
  position: absolute;
  max-width: 1240px;
  width: 92%;
  margin: auto;
  z-index: 5;
  img {
    width: 100%;
    max-width: 1240px;
    margin: auto;
  }
`;
