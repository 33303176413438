import styled from "styled-components";
import waves from "../../images/soundWaves2.png";
import theme from "../../styles/theme";

export const ContainerWhat = styled.div`
  margin-top: 4rem;
  display: flex;
  justify-content: center;
  flex-direction: column;
  background-image: url(${waves});
  background-size: contain;
  background-repeat: repeat-x;
  background-position: center;
  padding-top: 2rem;
  padding-bottom: 2rem;
  @media (max-width: ${theme.dimensions.mobile}) {
    background-size: cover;
  }
`;

export const Row = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: ${({ just }) => (just ? just : "space-between")};
  margin-left: auto;
  margin-right: auto;
  margin-bottom: ${({ nomargin }) => (nomargin ? "0" : "2rem")};
  width: 92%;
  max-width: 1240px;
  flex-wrap: wrap;
  @media (max-width: ${theme.dimensions.mobile}) {
    margin-bottom: 0;
  }
`;
export const Items = styled.span`
  display: flex;
  font-size: 2.05rem;
  font-family: "DM Sans";
  color: white;
  font-weight: 500;
  justify-content: center;
  animation-name: example;
  animation-duration: 3s;
  animation-iteration-count: infinite;
  animation-direction: alternate;
  animation-delay: ${({ random }) => (random ? random + "s" : "0s")};
  @keyframes example {
    0% {
      color: rgba(255, 112, 112, 1);
    }
    100% {
      color: white;
    }
  }

  @media (max-width: ${theme.dimensions.mobile}) {
    flex-basis: 50%;
    flex-grow: 1;
    margin-bottom: 1.5rem;
    font-size: 1.5rem;
  }
`;
export const HeadingWhat = styled.p`
  color: black;
  max-width: 1240px;
  width: 92%;
  font-size: ${({ fontSize }) => (fontSize ? fontSize : "1em")};
  font-family: "DM Sans";
  font-weight: 800;
  text-align: ${({ align }) => (align ? align : "center")};
  color: white;
  margin: auto;
  @media (max-width: ${theme.dimensions.mobile}) {
    font-size: 3.125rem;
  }
`;
