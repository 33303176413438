import React from "react";
import Button from "../../design-framework/button/button";
import {
  ButtonAnchor,
  DownloadContainer,
  DownloadContent,
  DownloadPara,
  DownloadWrapper,
  MobileObject,
} from "../../styles/DownloadSection/DownloadSection.styled";
import galaxy from "../../images/AndroidMockup.png";
import playstoreLogo from "../../images/playstorewhite.svg";

const DownloadSection = () => {
  return (
    <DownloadWrapper>
      <DownloadContainer>
        <DownloadContent>
          <DownloadPara>
            download the app now and get started with quhu
          </DownloadPara>
          <ButtonAnchor
            href="https://play.google.com/store/apps/details?id=me.quhu"
            target="_blank"
            rel="noreferrer"
          >
            <Button
              variant="contained"
              size="large"
              role="presentation"
              color="primary"
            >
              <img
                src={playstoreLogo}
                width={16}
                style={{ marginRight: 20, color: "white" }}
                alt="playstore-logo"
              />
              Download Quhu on Playstore
            </Button>
          </ButtonAnchor>
        </DownloadContent>
        <MobileObject
          src={galaxy}
          type="image/svg+xml"
          alt="quhu-on-phone-mockup"
        ></MobileObject>
      </DownloadContainer>
    </DownloadWrapper>
  );
};

export default DownloadSection;
