import React from "react";
import { ThemeProvider, createGlobalStyle } from "styled-components";

import "normalize.css";

import "../../styles/typography.css";
import theme from "../../styles/theme";

const GlobalStyle = createGlobalStyle`
  body {
    background-color: ${({ theme }) => theme.colors.darkPurple};
    color: ${({ theme }) => theme.colors.white};
    margin: 0;
    padding: 0;
    font-family: "DM Sans", sans-serif;
  }

  a {
    text-decoration: none;
    color: white;
  }
`;

const Layout = ({ children }) => (
  <ThemeProvider theme={theme}>
    <GlobalStyle />
    {children}
  </ThemeProvider>
);

export default Layout;
