import React from "react";
import androidLive from "../../images/android-live.png";
import {
  ParaFirst,
  ParaDivFirst,
  ParaContainerFirst,
  HeadingDivFirst,
  HeadingFirst,
  GoLiveWrapper,
  AndroidLiveImg,
  ImageOne,
  ImageTwo,
  ImageThree,
  ImageFour,
  ImageFive,
  ImageSix,
  ImageSeven,
  ImageEight,
  GoLiveInnerWrapper,
} from "../../styles/GoLive/GoLive.styled";
import one from "../../images/1.png";
import two from "../../images/2.png";
import three from "../../images/3.png";
import four from "../../images/4.png";
import five from "../../images/5.png";
import six from "../../images/6.png";
import seven from "../../images/7.png";
import eight from "../../images/8.png";
const GoLive = () => {
  return (
    <>
      <ParaContainerFirst flexDirection="column">
        <HeadingDivFirst align="flex-start">
          <HeadingFirst maxwidth="19.875rem" fontSize="5rem" align="flex-start">
            go LIVE on quhu
          </HeadingFirst>
        </HeadingDivFirst>
        <ParaDivFirst align="flex-end">
          <ParaFirst align="flex-end">
            Be the first to discover the most trending bites on quhu. bump into
            old friends and make new ones too.
          </ParaFirst>
        </ParaDivFirst>
      </ParaContainerFirst>
      <GoLiveWrapper>
        <GoLiveInnerWrapper>
          <AndroidLiveImg src={androidLive}></AndroidLiveImg>
          <ImageOne src={one}></ImageOne>
          <ImageTwo src={two}></ImageTwo>
          <ImageThree src={three}></ImageThree>
          <ImageFour src={four}></ImageFour>
          <ImageFive src={five}></ImageFive>
          <ImageSix src={six}></ImageSix>
          <ImageSeven src={seven}></ImageSeven>
          <ImageEight src={eight}></ImageEight>
        </GoLiveInnerWrapper>
      </GoLiveWrapper>
    </>
  );
};

export default GoLive;
