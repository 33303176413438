import styled from "styled-components";
import theme from "../../styles/theme";
export const Circle = styled.div`
  border: 1px solid white;
  border-radius: 50%;
  width: ${({ height }) => height + "rem"};
  height: ${({ height }) => height + "rem"};
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${({ dark }) => (dark ? "#3A2C62" : "white")};
  color: ${({ dark }) => (dark ? "white" : "#3A2C62")};
  position: absolute;
  right: 0;
  box-shadow: ${({ dark }) =>
    dark
      ? "inset -19.5577px 23.0091px 108.143px rgba(125, 89, 229, 0.3), inset 41.4163px -63.2749px 115.045px #7D59E5;"
      : "inset -15.2816px 17.9784px 84.4985px rgba(125, 89, 229, 0.3), inset 32.3611px -49.4406px 89.892px #7D59E5"};
  &:hover {
    color: white;
    background-color: #3a2c62;
    box-shadow: inset -19.5577px 23.0091px 108.143px rgba(125, 89, 229, 0.3),
      inset 41.4163px -63.2749px 115.045px #7d59e5;
  }
  @media (max-width: ${theme.dimensions.mobile}) {
    // max-width: ${({ height }) => height * 0.77 + "rem"};
    // max-height: ${({ height }) => height * 0.77 + "rem"};
    // color: red;
  }
`;

export const CircleContent = styled.p`
  max-width: 90%;
  // font-size: 2em;
  text-align: center;
  font-weight: 700;
  font-family: "DM Sans";
  flex: 1 0 100%;
`;

export const CircleContentBold = styled.span`
  font-size: 1.3em;
  font-weight: 700;
  @media (max-width: ${theme.dimensions.mobile}) {
    font-size: 1.95rem;
  }
`;
export const CircleContainer = styled.div`
  font-family: "DM Sans";
  position: relative;
  right: 0;
  display: flex;
  flex-wrap: wrap;
  height: 100%;
  div#listen {
    right: 42.6%;
    top: 14.5%;
    font-size: 2rem;
    @media (max-width: ${theme.dimensions.mobile}) {
      font-size: 1.688rem;
      top: 2.5%;
      left: 0%;
      right: initial;
      width: 55vw;
      height: 55vw;
      z-index: 3;
    }
  }
  #create {
    right: 27.65%;
    top: 5%;
    z-index: 2;
    font-size: 1.75rem;
    @media (max-width: ${theme.dimensions.mobile}) {
      font-size: 1.5rem;
      top: 0;
      right: 0;
      width: 42vw;
      height: 42vw;
    }
  }
  #edit {
    right: 0.1%;
    top: 49%;
    font-size: 1.6rem;
    @media (max-width: ${theme.dimensions.mobile}) {
      font-size: 1.25rem;
      right: 0;
      width: 52vw;
      height: 52vw;
      top: 51%;
      z-index: 5;
    }
  }
  #comment {
    right: 0;
    font-size: 2.275rem;
    @media (max-width: ${theme.dimensions.mobile}) {
      font-size: 1.815rem;
      top: 33.5%;
      left: 0;
      width: 67vw;
      height: 67vw;
      z-index: 4;
    }
  }
  #go {
    right: 21%;
    top: 29.5%;
    font-size: 2.225rem;
    @media (max-width: ${theme.dimensions.mobile}) {
      font-size: 1.75rem;
      top: 15%;
      right: 0%;
      width: 63vw;
      height: 63vw;
      z-index: 3;
    }
  }
`;

export const CircleSectionImage = styled.img`
  max-width: 620px;
  max-height: 440px;
  position: absolute;
  bottom: 0;
  left: 0;
  @media (max-width: ${theme.dimensions.mobile}) {
    max-width: 100%;
  }
`;

export const CircleWrapper = styled.div`
  position: relative;
  height: 700px;
  max-width: 1240px;
  width: 92%;
  margin: auto;
  margin-bottom: 2em;
  @media (max-width: ${theme.dimensions.mobile}) {
    height: 1075px;
    margin-top: 6rem;
  }
`;
