import styled from "styled-components";
import golive from "../../images/golive-waves.svg";
import theme from "../../styles/theme";

export const HeadingFirst = styled.p`
  color: black;
  font-size: ${({ fontSize }) => (fontSize ? fontSize : "1em")};
  font-family: "DM Sans";
  font-weight: 800;
  text-align: ${({ align }) => (align ? align : "center")};
  color: white;
  max-width: 20rem;
  z-index: 2;
  @media (max-width: ${theme.dimensions.mobile}) {
    flex: 0 0 100%;
    font-size: 3.125rem;
    line-height: 3.375rem;
  }
`;

export const ParaDivFirst = styled.div`
  flex: 0 0 45%;
  display: flex;
  justify-content: ${({ align }) => (align ? align : "center")};
`;

export const ParaContainerFirst = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 1em auto;
  justify-content: space-between;
  max-width: 1240px;
  width: 92%;
  @media (max-width: ${theme.dimensions.mobile}) {
    flex-direction: ${({ flexDirection }) => flexDirection};
    align-items: flex-start;
    width: 87%;
  }
`;

export const ParaFirst = styled.p`
  font-weight: 400;
  font-family: "DM Sans";
  text-align: ${({ align }) => (align ? align : "center")};
  font-size: 1.25rem;
  color: rgba(145, 137, 170, 1);
  line-height: 2.625rem;
  max-width: 60%;
  @media (max-width: ${theme.dimensions.mobile}) {
    text-align: ${({ align }) => (align === "flex-start" ? "right" : "left")};
    font-size: 1rem;
    line-height: 1.75rem;
    max-width: 100%;
  }
`;

export const HeadingDivFirst = styled.div`
  display: flex;
  justify-content: ${({ align }) => (align ? align : "flex-end")};
  flex: 0 0 45%;
`;

export const GoLiveWrapper = styled.div`
  background-image: url(${golive});
  margin-top: 0rem;
  display: flex;
  justify-content: center;
  flex-direction: column;
  background-size: 100%;
  background-repeat: no-repeat;
  background-position: center;
  height: 620px;
  @media (max-width: ${theme.dimensions.mobile}) {
    background-size: auto;
  }
`;

export const GoLiveInnerWrapper = styled.div`
  max-width: 1240px;
  width: 92%;
  height: 100%;
  position: relative;
  margin: auto;
  overflow: hidden;
  @media (max-width: ${theme.dimensions.mobile}) {
    width: 100%;
  }
`;

export const AndroidLiveImg = styled.img`
  width: 300px;
  height: auto;
  position: absolute;
  right: 0;
  @media (max-width: ${theme.dimensions.mobile}) {
    width: 220px;
    left: 0;
    margin: auto;
  }
`;

export const ImageOne = styled.img`
  height: 164px;
  width: 164px;
  position: absolute;
  left: 0;
  top: 100px;
  @media (max-width: ${theme.dimensions.mobile}) {
    width: 112px;
    height: 112px;
    top: 160px;
    left: -10%;
  }
`;
export const ImageTwo = styled.img`
  height: 79px;
  width: 79px;
  position: absolute;
  bottom: 170px;
  left: -100px;
  @media (max-width: ${theme.dimensions.mobile}) {
    display: none;
    left: 0;
  }
`;
export const ImageThree = styled.img`
  height: 106px;
  width: 106px;
  position: absolute;
  top: 340px;
  left: 112px;
  @media (max-width: ${theme.dimensions.mobile}) {
    display: none;
    left: 0;
  }
`;
export const ImageFour = styled.img`
  height: 204px;
  width: 204px;
  position: absolute;
  left: 270px;
  top: 200px;
  @media (max-width: ${theme.dimensions.mobile}) {
    bottom: 20px;
    width: 140px;
    height: 140px;
    top: auto;
    left: 0;
  }
`;
export const ImageFive = styled.img`
  height: 84px;
  width: 84px;
  position: absolute;
  left: 620px;
  top: 150px;
  @media (max-width: ${theme.dimensions.mobile}) {
    display: none;
    left: 0;
  }
`;
export const ImageSix = styled.img`
  height: 111px;
  width: 111px;
  position: absolute;
  left: 475px;
  top: 375px;
  @media (max-width: ${theme.dimensions.mobile}) {
    right: -5%;
    height: 76px;
    width: 76px;
    left: initial;
    top: initial;
    bottom: 275px;
  }
`;
export const ImageSeven = styled.img`
  height: 135px;
  width: 135px;
  position: absolute;
  left: 725px;
  top: 270px;
  @media (max-width: ${theme.dimensions.mobile}) {
    display: none;
    left: 0;
  }
`;
export const ImageEight = styled.img`
  height: 104px;
  width: 104px;
  position: absolute;
  right: -100px;
  top: 300px;
  @media (max-width: ${theme.dimensions.mobile}) {
    bottom: 0;
    width: 72px;
    height: 72px;
    top: 0;
    right: 0;
  }
`;
