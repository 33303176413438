import React, { useEffect, useRef } from "react";
import { gsap } from "gsap";
import Button from "../../design-framework/button/button";
import theme from "../../styles/theme";
import {
  CirclesBg,
  HeadingWrapper,
  HeadingWrapperLeft,
  HeadingWrapperRight,
  HeroHeading,
  HeroMockup,
  HeroWrapper,
  InnerWrapper,
  LoaderImage,
  MockupAndButton,
  PurpleGradient,
  RedGradient,
  SubPara,
} from "../../styles/HeroSection/HeroSection.styled";
import heroMockup from "../../images/Hero-mockup.png";
import playstoreLogo from "../../images/playstorewhite.svg";
import circlesBg from "../../images/circles-bg.png";
import loaderImage from "../../images/loaderImage.svg";
import incompleteHeroMockup from "../../images/incompleteHeroMockup.png";
import emptyHeroMockup from "../../images/herosection/emptyHeroSection.png";
import heroMockupContent from "../../images/herosection/heroMockupContent.png";
import peopleGroup from "../../images/herosection/peopleGroup.png";

const detectMob = () => {
  return (
    window.innerWidth <=
    parseInt(
      theme.dimensions.mobile.slice(0, theme.dimensions.mobile.length - 2)
    )
  );
};

const HeroSection = () => {
  const heroWrapperRef = useRef();
  const heromockup = useRef();
  const headingleft = useRef();
  const headingright = useRef();
  const redGradient = useRef();
  const purpleGradient = useRef();
  const downloadButton = useRef();
  const bgCircles = useRef();
  const para = useRef();
  const loaderImageRef = useRef();
  const peopleGroupRef = useRef();
  const heroMockupContentRef = useRef();
  const emptyHeroMockupRef = useRef();
  useEffect(() => {
    gsap
      .timeline()
      .from(heroWrapperRef.current, { autoAlpha: 0 })
      .fromTo(
        loaderImageRef.current,
        { duration: 0.8 },
        { scale: 0.01, duration: 0.8, yPercent: -20 },
        "+=0.5"
      )
      .set(loaderImageRef.current, { visibility: "hidden" })
      .fromTo(
        emptyHeroMockupRef.current,
        { duration: 1, opacity: 0, yPercent: 40 },
        { opacity: 1, duration: 1, zIndex: 2 },
        "-=.5"
      )
      .fromTo(
        peopleGroupRef.current,
        { yPercent: 200, opacity: 0, scale: 0.4 },
        { yPercent: 140, opacity: 1, scale: 0.4, duration: "0.5" }
      )
      .fromTo(
        heroMockupContentRef.current,
        { yPercent: 100, opacity: 0 },
        { yPercent: 45, opacity: 1, duration: "0.5" },
        "-=0.5"
      )
      .fromTo(
        headingleft.current,
        { duration: 0.5, yPercent: 100, opacity: 0 },
        { duration: 0.5, yPercent: 30, opacity: 1 }
      )
      .fromTo(
        peopleGroupRef.current,
        { duration: 0.5, scale: 0.4 },
        { duration: 0.5, scale: 1 },
        "-=0.5"
      )
      .fromTo(
        headingright.current,
        { duration: 0.5, yPercent: 100, opacity: 0 },
        { duration: 0.5, yPercent: 70, opacity: 1 }
      )
      .from([redGradient.current, purpleGradient.current], {
        duration: 1.5,
        scale: 0,
        opacity: 0,
        onComplete: () => {
          document.querySelector(".content").classList.remove("is-loading");
          if (detectMob() === false) {
            document
              .querySelector(".hero-wrapper")
              .classList.add("paddingBottom");
          }
        },
      })
      .to(
        [
          heromockup.current,
          headingleft.current,
          headingright.current,
          heroMockupContentRef.current,
          emptyHeroMockupRef.current,
          peopleGroupRef.current,
        ],
        {
          duration: 1.5,
          yPercent: 0,
        },
        "-=1.5"
      )
      .from([downloadButton.current, para.current, bgCircles.current], {
        duration: 1,
        opacity: 0,
        onComplete: () => {
          document.querySelector(".hero-wrapper").style.height = "100%";
        },
      });
  });

  return (
    <HeroWrapper className="hero-wrapper" ref={heroWrapperRef}>
      {/* <LoaderImage> */}
      <LoaderImage ref={loaderImageRef}>
        <img src={loaderImage}></img>
      </LoaderImage>
      <InnerWrapper>
        <HeadingWrapperLeft>
          <HeroHeading maxwidth="250px" ref={headingleft}>
            Get Heard
          </HeroHeading>
          <RedGradient ref={redGradient} />
        </HeadingWrapperLeft>
        <MockupAndButton>
          <div ref={heromockup} style={{ zIndex: "2", position: "relative" }}>
            <HeroMockup src={emptyHeroMockup} ref={emptyHeroMockupRef} />
            <img
              ref={heroMockupContentRef}
              src={heroMockupContent}
              style={{
                position: "absolute",
                left: "12.5px",
                width: "224px",
                top: "35px",
              }}
            ></img>
            <img
              ref={peopleGroupRef}
              src={peopleGroup}
              style={{
                position: "absolute",
                width: "206px",
                left: "20px",
                top: "90px",
              }}
            ></img>
            {/* <img src={incompleteHeroMockup}></img> */}
          </div>
          <a
            style={{ zIndex: "2", marginTop: "18px" }}
            href="https://play.google.com/store/apps/details?id=me.quhu"
            target="_blank"
            rel="noreferrer"
            ref={downloadButton}
          >
            <Button
              variant="contained"
              size="large"
              role="presentation"
              color="primary"
            >
              <img
                src={playstoreLogo}
                alt="Quhu"
                width={16}
                style={{ marginRight: 20, color: "white" }}
                alt="playstore-logo"
              />
              Download Quhu
            </Button>
          </a>
        </MockupAndButton>
        <HeadingWrapperRight>
          <HeroHeading maxwidth="470px" ref={headingright}>
            Get Discovered
          </HeroHeading>
          <PurpleGradient ref={purpleGradient} />
        </HeadingWrapperRight>
        {/* <div ref={para}> */}
        <SubPara ref={para}>
          Vocalise your talent through audio bites, get connected to your
          community and share your creations with the world using the Quhu audio
          platform.
        </SubPara>
        {/* </div> */}
        <CirclesBg src={circlesBg} ref={bgCircles}></CirclesBg>
      </InnerWrapper>
    </HeroWrapper>
  );
};
export default HeroSection;
