import styled from "styled-components";
import theme from "../../styles/theme";

export const CreatorSectionWrapper = styled.div`
  display: flex;
  height: 650px;
  flex-direction: row;
  align-items: center;
  max-width: 1240px;
  width: 92%;
  margin: auto;
  margin-top: 12.5rem;
  justify-content: space-between;
  &:before {
    content: "";
    position: absolute;
    left: -30%;
    width: 100%;
    height: 1200px;
    background: #7d59e5;
    filter: blur(293px);
    opacity: 0.3;
    border-radius: 50%;
    @media (max-width: ${theme.dimensions.mobile}) {
      left: 0;
      right: 0;
      height: 800px;
    }
  }
  @media (max-width: ${theme.dimensions.mobile}) {
    width: 100%;
    flex-direction: column;
    height: 1100px;
  }
`;

export const Heading = styled.p`
  max-width: ${({ maxwidth }) => (maxwidth ? maxwidth : "auto")};
  color: black;
  font-size: ${({ fontSize }) => (fontSize ? fontSize : "1em")};
  font-family: "DM Sans";
  font-weight: 800;
  text-align: ${({ align }) => (align ? align : "center")};
  color: white;
  line-height: 4.75rem;
  margin: auto;
  z-index: 2;
`;

export const SubHeading = styled.p`
  max-width: ${({ maxwidth }) => (maxwidth ? maxwidth : "auto")};
  color: black;
  font-size: 2.75rem;
  font-family: "DM Sans";
  font-weight: 500;
  text-align: ${({ align }) => (align ? align : "left")};
  color: white;
  margin-bottom: 1.5rem;
  margin-top: 0;
  z-index: 2;
  @media (max-width: ${theme.dimensions.mobile}) {
    align-items: center;
    justify-content: center;
    text-align: center;
    font-size: 2.125rem;
  }
`;

export const SubPara = styled.p`
  // max-width: ${({ maxwidth }) => (maxwidth ? maxwidth : "auto")};
  color: black;
  font-size: ${({ fontSize }) => (fontSize ? fontSize : "1.125rem")};
  font-family: "DM Sans";
  font-weight: 400;
  text-align: ${({ align }) => (align ? align : "left")};
  color: white;
  line-height: 1.875rem;
  z-index: 2;
  width: 400px;
  @media (max-width: ${theme.dimensions.mobile}) {
    align-items: center;
    justify-content: center;
    text-align: center;
    width: 100%;
    line-height: 1.375rem;
  }
`;

export const Div = styled.div`
  transition: transform 1s ease;
  transform: ${({ transform }) =>
    transform === "show" ? "translateX(0)" : "translateX(101%)"};
  position: absolute;
  right: 0;
  width: 400px;
  @media (max-width: ${theme.dimensions.mobile}) {
    width: 100%;
  }
`;

export const AudioLive = styled.img`
  position: absolute;
  top: ${({ highlight }) => (highlight === 0 ? "0px" : "0%")};
  left: ${({ highlight }) => (highlight === 0 ? "480px" : "480px")};
  cursor: pointer;
  width: 204px;
  @media (max-width: ${theme.dimensions.mobile}) {
    width: 160px;
    bottom: 15%;
    top: unset;
    left: 0;
  }
`;

export const AudioBite = styled.img`
  position: absolute;
  top: ${({ highlight }) => (highlight === 1 ? "35%" : "35%")};
  cursor: pointer;
  left: ${({ highlight }) => (highlight === 1 ? "530px" : "530px")};
  width: 204px;
  @media (max-width: ${theme.dimensions.mobile}) {
    width: 160px;
    bottom: 5%;
    top: unset;
    left: 50%;
    transform: translate(-50%, 0);
    right: 0;
  }
`;
export const AudioGame = styled.img`
  position: absolute;
  top: ${({ highlight }) => (highlight === 2 ? "70%" : "70%")};
  cursor: pointer;
  left: ${({ highlight }) => (highlight === 2 ? "460px" : "460px")};
  width: 204px;
  @media (max-width: ${theme.dimensions.mobile}) {
    width: 160px;
    bottom: 15%;
    top: unset;
    left: unset;
    right: 0;
  }
`;

export const ContentWrapper = styled.div`
  text-align: center;
  position: relative;
  max-width: 36%;
  right: 0;
  overflow: hidden;
  width: 100%;
  height: 600px;
  display: flex;
  align-items: center;
  justify-content: center;
  @media (max-width: ${theme.dimensions.mobile}) {
    max-width: 81%;
    justify-content: center;
    align-items: flex-start;
    height: 300px;
    // margin-top: 150px;
  }
`;

export const MockupWithBg = styled.img`
  max-width: 600px;
  @media (max-width: ${theme.dimensions.mobile}) {
    max-width: 550px;
    margin-left: 50%;
    transform: translate(-50%);
  }
`;
