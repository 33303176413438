import React, { useState, useEffect } from "react";
import {
  CreatorSectionWrapper,
  Heading,
  SubHeading,
  SubPara,
  Div,
  AudioLive,
  AudioBite,
  AudioGame,
  ContentWrapper,
  MockupWithBg,
} from "../../styles/CreatorSection/CreatorSection.styled";
import live from "../../images/audiosection/audiolive.png";
import livehighlight from "../../images/audiosection/audiolivehighlight.png";
import bite from "../../images/audiosection/audiobite.png";
import bitehighlight from "../../images/audiosection/audiobitehighlight.png";
import game from "../../images/audiosection/audiogame.png";
import gamehighlight from "../../images/audiosection/audiogamehighlight.png";
import mockupWithBg from "../../images/mockup-with-bg.png";

const CreatorSection = () => {
  const [el, setEl] = useState(0);
  const [pause, setPause] = useState(false);
  useEffect(() => {
    const timer = setInterval(() => {
      if (pause !== true) {
        setEl((el) => (el + 1) % 4);
      }
    }, 4000);
    return () => clearInterval(timer);
  }, []);

  const content = [
    {
      heading: "single creator tool, for all things audio",
    },
    {
      heading: "Go Live",
      para: "Start your own live shows and engage with your audience in real-time using our Go-Live feature. Quhu enables you to engage with your audience just the way you want. Whether you’re using the live feature to connect with your audience in real-time or collaborate with other creators, never let your creation go to waste.",
      id: "two",
    },
    {
      heading: "Create Audio Bites",
      para: "With Quhu’'s user-friendly and intuitive sound studio app, creating and sharing your own mini audio bites with the world is easy and quick.",
      id: "one",
    },
    {
      heading: "Play Audio Games",
      para: "Quhu’s audio games feature lets you engage with your audience in real-time and build a solid fan base..",
      id: "three",
    },
  ];

  return (
    // Change this to FLEX
    <CreatorSectionWrapper>
      <div
        style={{
          position: "relative",
          flexGrow: "1",
          height: "600px",
          width: "100%",
          overflow: "hidden",
        }}
      >
        {/* CHANGE MAX WIDTH OF THIS TO 100% AT 550 PX MEDIA QUERY */}
        <MockupWithBg alt="android-mockup" src={mockupWithBg} />

        <AudioLive
          highlight={el}
          onMouseEnter={() => {
            setPause(true);
            setEl(1);
          }}
          onMouseLeave={() => setPause(false)}
          src={el === 1 ? livehighlight : live}
        ></AudioLive>

        <AudioBite
          onMouseEnter={() => {
            setPause(true);
            setEl(2);
          }}
          onMouseLeave={() => setPause(false)}
          highlight={el}
          src={el === 2 ? bitehighlight : bite}
        ></AudioBite>

        <AudioGame
          onMouseEnter={() => {
            setPause(true);
            setEl(3);
          }}
          onMouseLeave={() => setPause(false)}
          highlight={el}
          src={el === 3 ? gamehighlight : game}
        ></AudioGame>
      </div>
      <ContentWrapper>
        <Div
          transform={el === 0 ? "show" : "hide"}
          onMouseEnter={() => setPause(!pause)}
          onMouseLeave={() => setPause(!pause)}
        >
          <Heading align="right" maxwidth="265px" fontSize="4.5rem">
            single creator tool, for all things audio
          </Heading>
        </Div>

        <Div
          id={content[1].id}
          transform={el === 1 ? "show" : "hide"}
          onMouseEnter={() => setPause(!pause)}
          onMouseLeave={() => setPause(!pause)}
        >
          <SubHeading>{content[1].heading}</SubHeading>
          <SubPara>{content[1].para}</SubPara>
        </Div>
        <Div
          id={content[2].id}
          transform={el === 2 ? "show" : "hide"}
          onMouseEnter={() => setPause(!pause)}
          onMouseLeave={() => setPause(!pause)}
        >
          <SubHeading>{content[2].heading}</SubHeading>
          <SubPara>{content[2].para}</SubPara>
        </Div>
        <Div
          id={content[3].id}
          transform={el === 3 ? "show" : "hide"}
          onMouseEnter={() => setPause(!pause)}
          onMouseLeave={() => setPause(!pause)}
        >
          <SubHeading>{content[3].heading}</SubHeading>
          <SubPara maxwidth="400px">{content[2].para}</SubPara>
        </Div>
      </ContentWrapper>
    </CreatorSectionWrapper>
  );
};

export default CreatorSection;
