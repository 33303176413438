import * as React from "react";
import Layout from "../components/common/Layout";
import Header from "../components/common/Header";
import Footer from "../components/common/Footer";
import HeroSection from "../components/home/HeroSection";
import WhatsYour from "../components/home/WhatsYour";
import CircleBlobs from "../components/home/CircleBlobs";
import ParaHeading from "../components/home/ParaHeading";
import DownloadSection from "../components/home/DownloadSection";
import CreatorSection from "../components/home/CreatorSection";
import GoLive from "../components/home/GoLive";
import { Main } from "../styles/home";
import SEO from "../components/common/seo";

const IndexPage = () => {
  return (
    <Layout>
      <SEO />
      <Main>
        <Header />
        <HeroSection />
        <div className="content is-loading">
          <CircleBlobs />
          <CreatorSection />
          <GoLive />
          <ParaHeading />
          <WhatsYour />
          <DownloadSection />
          <Footer />
        </div>
      </Main>
    </Layout>
  );
};

export default IndexPage;
