import styled from "styled-components";

export const HeaderWrapper = styled.header`
  height: 70px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${({ theme }) => theme.colors.darkPurple};
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 6;
  visibility: hidden;
`;

export const HeaderContent = styled.div`
  max-width: 1240px;
  width: 92%;
  margin: 0 auto;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;
