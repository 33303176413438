import React, { useEffect, useRef } from "react";
import { Link } from "gatsby";
import Button from "../../design-framework/button/button";
import { gsap } from "gsap";
import quhulogo from "../../images/quhu-logo.png";
import playstoreLogo from "../../images/playstore-purple.png";
import { HeaderWrapper, HeaderContent } from "../../styles/header";

const Header = () => {
  const logoRef = useRef();
  const HeaderWrapperRef = useRef();
  const downloadButtonRef = useRef();
  useEffect(() => {
    gsap
      .timeline()
      .from(HeaderWrapperRef.current, { autoAlpha: 0 })
      .fromTo(
        logoRef.current,
        { opacity: 0, duration: 0.8, yPercent: 70 },
        { opacity: 1, duration: 0.8, yPercent: 70 },
        "+=.5"
      )
      .fromTo(
        [downloadButtonRef.current],
        { opacity: 0 },
        { opacity: 1, duration: "0.5" },
        "+=2.3"
      )
      .to(logoRef.current, { yPercent: 0, duration: "1.0" }, "-=0.5");
  });

  return (
    <HeaderWrapper ref={HeaderWrapperRef}>
      <HeaderContent>
        <Link to="/" ref={logoRef}>
          <img src={quhulogo} alt="Quhu" width={126} style={{ zIndex: 2 }} />
        </Link>
        <a
          href="https://play.google.com/store/apps/details?id=me.quhu"
          target="_blank"
          rel="noreferrer"
          ref={downloadButtonRef}
        >
          <Button variant="outlined" color="secondary" role="presentation">
            <img
              src={playstoreLogo}
              alt="Quhu"
              width={16}
              style={{ marginRight: 8 }}
            />
            Download
          </Button>
        </a>
      </HeaderContent>
    </HeaderWrapper>
  );
};

export default Header;
