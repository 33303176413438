import styled from "styled-components";
import theme from "../../styles/theme";

export const DownloadWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 12rem;
  @media (max-width: ${theme.dimensions.mobile}) {
    height: 500px;
    margin: auto;
    margin-top: 20rem;
  }
`;

export const DownloadContainer = styled.div`
  background: linear-gradient(275.22deg, #1e1144 2.19%, #4c3886 92.49%);
  display: flex;
  justify-content: space-between;
  height: 402px;
  max-width: 1240px;
  width: 92%;
  border-radius: 47px;
  align-items: flex-start;
  position: relative;
  @media (max-width: ${theme.dimensions.mobile}) {
    border-radius: 34px;
  }
`;

export const DownloadContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 89px 74px;
  max-width: 65%;
  height: 100%;
  box-sizing: border-box;
  align-items: flex-start;
  @media (max-width: ${theme.dimensions.mobile}) {
    max-width: 100%;
    padding: 0;
  }
`;

export const MobileObject = styled.img`
  margin-right: 8rem;
  position: absolute;
  right: 0;
  bottom: 0;
  max-width: 300px;
  @media (max-width: ${theme.dimensions.mobile}) {
    max-width: 260px;
    margin: 0 auto;
    left: 0;
  }
`;

export const DownloadPara = styled.p`
  font-size: 3.1075rem;
  margin: 0;
  font-weight: bold;
  @media (max-width: ${theme.dimensions.mobile}) {
    position: absolute;
    top: -65%;
    text-align: center;
    font-size: 2.125rem;
    line-height: 2.68rem;
    max-width: 310px;
    margin: auto;
    left: 0;
    right: 0;
  }
`;

export const ButtonAnchor = styled.a`
  @media (max-width: ${theme.dimensions.mobile}) {
    position: absolute;
    top: 115%;
  }
`;
