import styled, { css } from "styled-components";
import theme from "../../styles/theme";

const colorMap = {
  primary: theme.colors.primary,
  secondary: theme.colors.white,
  primaryDark: theme.colors.primaryDark,
  secondaryDark: theme.colors.gray,
};

const containedButtonStyles = css`
  background-color: ${({ color }) => colorMap[color] || theme.colors.primary};
  color: white;
  border: none;

  &:hover {
    background-color: ${({ color }) =>
      colorMap[color + "Dark"] || theme.colors.primaryDark};
  }
`;

const outlinedButtonStyles = css`
  background-color: transparent;
  color: ${({ color }) => colorMap[color] || theme.colors.primary};
  border: 1px solid ${({ color }) => colorMap[color] || theme.colors.primary};

  &:hover {
    color: ${({ color }) =>
      colorMap[color + "Dark"] || theme.colors.primaryDark};
    border: 1px solid
      ${({ color }) => colorMap[color + "Dark"] || theme.colors.primaryDark};
  }
`;

const variantCssMapping = {
  contained: containedButtonStyles,
  outlined: outlinedButtonStyles,
};

const mediumButtonStyles = css`
  height: 44px;
  border-radius: 22px;
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
  padding: 10px 25px;
`;

const largeButtonStyles = css`
  height: 61px;
  border-radius: 31px;
  font-weight: bold;
  font-size: 20px;
  line-height: 26px;
  padding: 16px 32px;
`;

const sizeCssMapping = {
  medium: mediumButtonStyles,
  large: largeButtonStyles,
};

export const StyledButton = styled.button`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  cursor: pointer;
  ${({ variant }) => variantCssMapping[variant] || containedButtonStyles}
  ${({ size }) => sizeCssMapping[size] || mediumButtonStyles}
`;
