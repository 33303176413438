import { bool, node, oneOf } from "prop-types";
import React from "react";

import { StyledButton } from "./button.styled";

const Button = ({
  children,
  variant,
  size,
  type,
  color,
  disabled,
  ...props
}) => (
  <StyledButton
    variant={variant}
    size={size}
    type={type}
    color={color}
    disabled={disabled}
    {...props}
  >
    {children}
  </StyledButton>
);

Button.defaultProps = {
  children: null,
  variant: "contained",
  size: "medium",
  type: "button",
  color: "primary",
  disabled: false,
};

Button.propTypes = {
  children: node,
  variant: oneOf(["contained", "outlined"]),
  size: oneOf(["medium", "large"]),
  type: oneOf(["button", "submit"]),
  color: oneOf(["primary", "secondary"]),
  disabled: bool,
};

export default Button;
